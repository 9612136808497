<template>
  <vx-card class="insert-cost main-box md:w-1/2 top-zero-radius p-2">

    <div class="insert-cost-fields">
      <div class="insert-cost-field mt-5">
        <custom-select :label="$t('treasury.cost.labels.account')"
                       :default="newDocument.account"
                       :options="costAccounts"
                       v-model="newDocument.account"/>
      </div>

      <div class="insert-cost-fields mt-3" v-if="checkUserPermissions('cost.dynamicDate')">
        <custom-date-picker-input :label="$t('treasury.cost.labels.date')"
                                  type="datetime"
                                  :input-format="$localeValidator('moment.dateTime')"
                                  :is-empty="true"
                                  :max="currentDate"
                                  :placeholder="$t('treasury.cost.placeholder.date')"
                                  :invalid-text="$t('treasury.cost.validators.dateHelp', {date: `${currentDate.split(' ')[1]} ${currentDate.split(' ')[0]}`})"
                                  :regex="dateTimeRegex"
                                  v-model="newDocument.date"/>
      </div>

      <div class="insert-cost-field mt-3">
        <custom-select :label="$t('treasury.cost.labels.paymentFrom')"
                       input
                       :default="newDocument.paymentFrom"
                       :options="paymentFromOptions"
                       v-model="newDocument.paymentFrom"/>
      </div>

      <div class="insert-cost-field mt-3"
           v-if="newDocument.paymentFrom.value.type === 'bank'">
        <custom-select :label="$t('treasury.cost.labels.paymentType')"
                       :default="newDocument.costType"
                       :options="costTypes"
                       @suggest:selected="handleCostTypeSelected"
                       v-model="newDocument.costType"/>
      </div>

      <div class="insert-payment-field mt-3" v-if="['ctc', 'iban'].indexOf(newDocument.costType.value) > -1">
        <custom-price-input :label="$t('treasury.cost.labels.bankWage')"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            v-model="newDocument.bankWage"/>
      </div>

      <div class="insert-payment-field mt-3" v-if="newDocument.paymentFrom.value.id > 0">
        <custom-price-input :label="newDocument.paymentFrom.value.type === 'bank' ? $t('treasury.cost.labels.bankBalance') : $t('treasury.cost.labels.cashBoxBalance')"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :disabled="true"
                            :value="newDocument.paymentFrom.value.balance"/>
      </div>
    </div>

    <div class="insert-cost-fields mt-3">
      <div class="insert-cost-field">
        <custom-price-input :label="$t('treasury.cost.labels.price')"
                            :classes="{'w-full': true}"
                            v-model="newDocument.price"/>
      </div>

      <div class="insert-cost-field">
        <custom-validate-textarea :label="$t('treasury.cost.labels.description')"
                                  :classes="{'w-full': true}"
                                  :rtl="true"
                                  align="right"
                                  :is-empty="false"
                                  v-model="newDocument.description"/>
      </div>
    </div>

    <!-- type selector -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title="انتخاب روش پرداخت"
      @close="$router.replace({name: 'treasuryCost'})"
      :active.sync="selectCostTypeStatusPrompt">

      <div class="prompt-content p-3">

        <button class="select-type-button mt-3"
                @click="handleSelectReceiveType(1)">
          <custom-icon class="ml-2"
                       icon="MONEY"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.cost.paymentTypes.cash') }}
          </span>

          <vs-spacer/>
        </button>

        <button class="select-type-button mt-3"
                @click="handleSelectReceiveType(2)">
          <custom-icon class="ml-2"
                       icon="CREDIT_CARD"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('treasury.cost.paymentTypes.bank') }}
          </span>

          <vs-spacer/>
        </button>
      </div>
    </vs-prompt>
    <!-- /type selector -->

    <vs-button id="insertDocumentBTN" @click="insertDocument" class="useral-action-button"/>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import CustomValidateTextarea from '@/components/customInput/customValidateTextarea'
  import CustomPriceInput from '@/components/customInput/customPriceInput'
  import CustomIcon from '@/components/customIcon/customIcon'
  import SelectUsers from '@/views/admin/customers/select/selectUsers'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import CustomSelect from '@/components/customSelect/customSelect'
  import {getBanks} from '@/http/requests/treasury/banks'
  import {getCashBoxes} from '@/http/requests/treasury/cashBoxes'
  import {getCostTypes, insertCost, getCostTypes2} from '@/http/requests/treasury/cost'
  import {checkUserPermissions, getTimeFromServer} from "../../../../../assets/js/functions";
  import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
  import {getTreasuryRequirements} from "@/http/requests/requirements";

  export default {
    name: 'insertCost',
    metaInfo() {
      return {
        title: this.$t('treasury.cost.insert.title')
      }
    },
    components: {
      CustomDatePickerInput,
      CustomValidateTextarea, CustomPriceInput, CustomIcon, SelectUsers, CustomValidateInput, CustomSelect
    },
    data() {
      return {
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        selectCostTypeStatusPrompt: false,
        dateTimeRegex: this.$validator('regex.dateTime'),
        currentDate: '',
        type: 0,
        cashes: [],
        banks: [],
        costAccounts: [],
        paymentFromOptions: [],
        costTypes: [
          {
            label: this.$t('treasury.cost.bankTypes.card'),
            value: 'ctc'
          },
          {
            label: this.$t('treasury.cost.bankTypes.sheba'),
            value: 'iban'
          }
        ],
        bankWages: {
          cardToCard: 0,
          iban: 0
        },
        newDocument: {
          date: {
            value: '',
            isValid: true
          },
          price: {},
          description: {},
          account: {},
          paymentFrom: {
            label: '-',
            value: {
              id: 0,
              type: '',
              balance: {
                value: '0',
                isValid: true
              }
            }
          },
          cash: {
            label: '-',
            value: 0
          },
          bank: {
            label: '-',
            value: 0
          },
          costType: {
            label: '-',
            value: 0
          },
          bankWage: {
            value: '',
            isValid: true
          }
        },
        actions: [
          {
            toolbar: [
              {
                id: 'insertDocumentBTN',
                color: 'success',
                icon: 'SAVE',
                iconPack: 'useral'
              }
            ],
            leftToolbar: [
              {
                id: {name: 'treasuryCost'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
      })
      this.currentDate = moment(getTimeFromServer()).format(this.$localeValidator('moment.dateTime'))

      this.handleShowPaymentFromOptions()
      this.getTreasuryRequirements()
      this.getCostAccount()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      handleShowPaymentFromOptions() {
        if (checkUserPermissions('cash.show')) {
          this.getCashes()
        }
        if (checkUserPermissions('bank.show')) {
          this.getBanks()
        }
      },
      getCostAccount() {
        getCostTypes().then(response => {
          this.costAccounts = response.data.data.map(account => {
            return {
              label: this.generateAccountName(account),
              value: account.id
            }
          })
          this.newDocument.account = this.costAccounts[0]
        })
      },
      generateAccountName (account) {
        const accountName = account.slug ? this.$t(`accountancy.types.accountsLabel.${account.slug}`) : account.name
        return account.parent ? this.generateAccountName(account.parent) + ' - ' + accountName : accountName
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      handleSelectReceiveType(type) {
        this.type = type
        this.selectCostTypeStatusPrompt = false

        switch (type) {
          case 1:
            this.getCashes()
            this.$store.dispatch('setPageTitle', this.$t('treasury.cost.paymentTypes.cash'))
            break

          case 2:
            this.getBanks()
            this.$store.dispatch('setPageTitle', this.$t('treasury.cost.paymentTypes.bank'))
            break

          default:
            break
        }
      },
      getBanks() {
        getBanks().then(response => {
          const banks = response.data.data
          banks.forEach((bank) => {
            this.banks.push({
              label: bank.name,
              value: bank.id
            })
            this.paymentFromOptions.push({
              label: bank.name,
              value: {
                id: bank.id,
                type: 'bank',
                balance: {
                  value: bank.balance,
                  isValid: true
                }
              }
            })
          })
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      getCashes() {
        getCashBoxes().then(response => {
          const cashes = response.data.data
          cashes.forEach((cash) => {
            this.cashes.push({
              label: cash.name,
              value: cash.id
            })
            this.paymentFromOptions.push({
              label: cash.name,
              value: {
                id: cash.id,
                type: 'cash',
                balance: {
                  value: cash.balance,
                  isValid: true
                }
              }
            })
          })
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      getTreasuryRequirements() {
        getTreasuryRequirements().then(response => {
          const app = response.data.data
          this.bankWages.cardToCard = app.card_to_card_wage || ''
          this.bankWages.iban = app.iban_wage || ''
        })
      },
      handleCostTypeSelected () {
        if (this.newDocument.costType.value === 'ctc') {
          this.newDocument.bankWage.value = this.bankWages.cardToCard
        } else if (this.newDocument.costType.value === 'iban') {
          this.newDocument.bankWage.value = this.bankWages.iban
        }
      },
      insertDocument() {
        const cost = {
          account_id: this.newDocument.account.value,
          cost_type_id: this.newDocument.account.value,
          description: this.newDocument.description.value || '',
          price: (this.newDocument.price.value || 0).toString().replaceAll(',', '')
        }

        switch (this.newDocument.paymentFrom.value.type) {
          case 'cash':
            if (this.newDocument.paymentFrom.value.id !== 0) cost.cash_id = this.newDocument.paymentFrom.value.id
            break

          case 'bank':
            if (this.newDocument.paymentFrom.value.id !== 0) {
              cost.bank_id = this.newDocument.paymentFrom.value.id
              cost.payment_type = this.newDocument.costType.value
              cost.wage = this.newDocument.bankWage.value || this.newDocument.bankWage.placeholder || '0'
            }
            break
        }

        if (checkUserPermissions('cost.dynamicDate') && this.newDocument.date.isValid) {
          cost.date = this.newDocument.date.value
        }

        if (cost.account_id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.insert.notifications.invalidAccount'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (parseInt(cost.price) === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.insert.notifications.invalidPrice'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (cost.description.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.insert.notifications.invalidDescription'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.newDocument.paymentFrom.value.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.insert.notifications.invalidCash'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (!this.newDocument.date.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.validators.date'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.type === 2 && this.newDocument.costType.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cost.validators.type'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }
        // if (parseInt(this.newDocument.price.value) > this.newDocument.paymentFrom.value.balance.value) {
        //   this.$vs.notify({
        //     title: this.$t('alert.error.title'),
        //     text: this.$t('treasury.cost.validators.price'),
        //     time: 2400,
        //     color: 'danger',
        //     icon: 'icon-alert-circle',
        //     iconPack: 'feather'
        //   })
        //   return false
        // }

        // if (moment(this.newDocument.date.value).isAfter(this.currentDate)) {
        //   this.$vs.notify({
        //     title: this.$t('alert.error.title'),
        //     text: this.$t('treasury.cost.validators.future'),
        //     time: 2400,
        //     color: 'danger',
        //     icon: 'icon-alert-circle',
        //     iconPack: 'feather'
        //   })
        //   return false
        // }

        insertCost(cost).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.cost.insert.notifications.insert.success'),
            time: 2400,
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })

          if (this.$route.query.hasOwnProperty('user')) {
            this.$router.back()
          } else {
            this.$router.push({name: 'treasuryCost'})
          }
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 406:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('treasury.cost.insert.notifications.lowBalance'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('treasury.cost.insert.notifications.insert.error'),
                  time: 2400,
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .insert-cost {

    .insert-cost-fields {

      .insert-cost-field {
        position: relative;

        .suggest-modal-button, .new-suggest-modal-button {
          position: absolute;
          bottom: 0;
          right: 35px;
          top: 5px;
          width: 25px;
          height: 25px;
          padding: 5px;
          border: 1px solid #00bcd4;
          border-radius: .5rem;
          color: #00bcd4;
          background: white;
          cursor: pointer;
        }

        .new-suggest-modal-button {
          right: 5px;
          border: 1px solid #28C76F;
          color: #28C76F;
        }

        span.label {
          font-size: 12px;
          position: absolute;
          padding: 0 5px;
          left: 7px;
          top: -10px;
          z-index: 12000;
          background: linear-gradient(180deg, #f8f8f8, #ffffff);
        }
      }

      &:first-child {
        background: #cecece22;
        border: 1px solid #cecece;
        border-radius: .5rem;
        padding: 0 10px;

        span.label, .custom-validate-input .input-label {
          //backdrop-filter: sepia(1);
          //background: linear-gradient(180deg, #f8f8f8, #ffffff);
        }

        .custom-input {
          .input-label {
            background: linear-gradient(180deg, #f8f8f8, #ffffff);
          }
        }
      }
    }
  }
</style>
